const namespaced = true

const state = {}

const actions = {
    async getAllPresentations({dispatch}) {
        return await dispatch('database/getItems', {table: 'presentations'}, {root: true})
    },
    async getPresentationById({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'presentations', id: id}, {root: true})
    },
    async getAllPresentationsWithLink({dispatch}) {
        let presentations = [];
        let data = await dispatch('database/getMultiple', ['presentations', 'session_presentation_links', 'sessions', 'person_roles', 'persons'], {root: true});

        let linkedPresentations = data['session_presentation_links'];
        for (let i = 0; i < linkedPresentations.length; i++) {
            let link = linkedPresentations[i];
            let presentation = data['presentations'].find(pres => pres.id == link.presentation_id);
            let session = data['sessions'].find(sess => sess.id == link.session_id);
            link['speakers'] = [];
            link['color'] = session.color;
            link['sess_id'] = session.id;
            link['session'] = session;

            if (presentation) {
                link['title'] = presentation.title;
                link['pres_id'] = presentation.id;
                link['outline'] = presentation.outline;
                link['video_link'] = presentation.video_link;
                link['slides_id'] = presentation.slides_id;

                //person-block
                for (let k = 0; k < data['person_roles'].length; k++) {
                    let role = data['person_roles'][k];
                    if (role.presentation_id == presentation.id) {
                        for (let l = 0; l < data['persons'].length; l++) {
                            let person = data['persons'][l];
                            if (person.id == role.person_id) {
                                person.function = role.function;
                                if (JSON.stringify(link['speakers']).indexOf(person.id) == -1) {
                                    link['speakers'].push(person);
                                }
                            }
                        }
                    }
                }

                presentations.push(link);
            }

        }
        return presentations;


    },
    async getPresentationByLinkId({dispatch}, id) {
        let presentationTmp = [];
        let data = await dispatch('database/getMultiple', ['presentations', 'session_presentation_links', 'sessions'], {root: true});

        let linkedPresentation = data['session_presentation_links'].find(link => link.id == id);
        if (linkedPresentation) {
            presentationTmp = data['presentations'].find(pres => pres.id == linkedPresentation.presentation_id);
            let session = data['sessions'].find(sess => sess.id == linkedPresentation.session_id);
            presentationTmp.pres_id = presentationTmp.id;
            presentationTmp.id = linkedPresentation.id;
            presentationTmp.color = session.color;
            presentationTmp['code'] = linkedPresentation.code;
            presentationTmp['show_time'] = linkedPresentation.show_time;
            presentationTmp['start_time'] = linkedPresentation.start_time;
            presentationTmp['end_time'] = linkedPresentation.end_time;
            presentationTmp['session'] = session;
            presentationTmp['sess_id'] = session.id;

        }
        return presentationTmp;

    },
 



    async getPersons({dispatch}, id) {
        let persons = [];
        let data = await dispatch('database/getMultiple', ['person_roles', 'persons'], {root: true});

        for (let i = 0; i < data['person_roles'].length; i++) {
            let role = data['person_roles'][i];
            if (role.presentation_id == id) {
                for (let j = 0; j < data['persons'].length; j++) {
                    let person = data['persons'][j];
                    if (role.person_id == person.id) {
                        person.function = role.function;
                        person['order'] = role.order;
                        let repeated = false;
                        for(let x = 0; x <persons.length; x++){
                            console.log(x)
                            if(person.id == persons[x].id){
                                repeated = true;
                            }
                        }
                        if(!repeated){
                        persons.push(person);
                     }
                    }
                }
            }
        }
        console.log(persons)
        return persons;

    },
    async getSessions({dispatch}, presentation) {
        let data = await dispatch('database/getMultiple', ['person_roles', 'persons', 'session_presentation_links', 'sessions', 'sessiontypes', 'session_sessiontype_links', 'locations', 'session_location_links'], {root: true});
        let sessions = [];
        let sess_pres_links = data['session_presentation_links'].filter(sesspreslink => sesspreslink.presentation_id == presentation.pres_id);
       sess_pres_links.forEach(sess_pres_link => {
            let session = data['sessions'].find(sess => sess.id == sess_pres_link.session_id);
            console.log('1', session)
           
            let sesstype_links = data['session_sessiontype_links'].filter(sesstypelink => sesstypelink.session_id == session.id);
            sesstype_links.forEach(typelink => {
                console.log('2', typelink)

                let type = data['sessiontypes'].filter(type => type.id == typelink.sessiontype_id)[0];
                console.log('3', type)
                session.session_type_text = type.name;
                session.session_type_order = type.order;
                session.is_scientific = type.scientific;
            })
            let sessloc_link = data['session_location_links'].filter(sessloc_link => sessloc_link.session_id == session.id)[0];
            console.log('4', sessloc_link)
            let location = data['locations'].filter(location => location.id == sessloc_link.location_id)[0];
            console.log('5', location)
            session.location = location;
            session.location_order = location ? location.order : 0;

            session.chair = []
            session.speakers = [];
            
            
                let sessionroles = data['person_roles'].filter(sessrole => sessrole.session_id == session.id)
                let presroles = data['person_roles'].filter(presrole => presrole.presentation_id == presentation.id)
                sessionroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.chair.push(person);
                        }
                    }
                })
                presroles.forEach(role => {
                    let person = data['persons'].filter(person => person.id == role.person_id)[0];
                    if (person) {
                        person.function = role.function;
                        if (JSON.stringify(session.speakers).indexOf(person.id) == -1 && JSON.stringify(session.chair).indexOf(person.id) == -1) {
                            session.speakers.push(person);
                        }
                    }
                })
            

            sessions.push(session);
        })
        return sessions;
    },
    async getPresentationFavorite({dispatch}, id) {
        let presentation = await dispatch('database/getItem', {table: 'presentations', id: id}, {root: true})

        let data = await dispatch('database/getMultiple', ['session_presentation_links', 'sessions', 'person_roles', 'persons'], {root: true});

        let linkedPresentations = data['session_presentation_links'].filter(link=>link.presentation_id == id);
        for (let i = 0; i < linkedPresentations.length; i++) {
            let link = linkedPresentations[i];
            let session = data['sessions'].find(sess => sess.id == link.session_id);
            link['speakers'] = [];
            link['color'] = session.color;
            link['sess_id'] = session.id;
            link['session'] = session;

            if (presentation) {
                link['id'] = presentation.id
                link['title'] = presentation.title;
                link['pres_id'] = presentation.id;
                link['outline'] = presentation.outline;
                link['video_link'] = presentation.video_link;
                link['slides_id'] = presentation.slides_id;

                //person-block
                for (let k = 0; k < data['person_roles'].length; k++) {
                    let role = data['person_roles'][k];
                    if (role.presentation_id == presentation.id) {
                        for (let l = 0; l < data['persons'].length; l++) {
                            let person = data['persons'][l];
                            if (person.id == role.person_id) {
                                person.function = role.function;
                                if (JSON.stringify(link['speakers']).indexOf(person.id) == -1) {
                                    link['speakers'].push(person);
                                }
                            }
                        }
                    }
                }

                presentation = link
            }

        }
        return presentation;
    },
    async getPresentationLinkFavorite({dispatch}, id) {

        let persons = [];
    let data = await dispatch('database/getMultiple', ['presentations', 'session_presentation_links', 'sessions', 'person_roles', 'persons'], {root: true});

    let linkedPresentation = data['session_presentation_links'].find(link => link.id == id);
    let presentationTmp = data['presentations'].find(pres => pres.id == linkedPresentation.presentation_id);
    let session = data['sessions'].find(sess => sess.id == linkedPresentation.session_id);
    presentationTmp.pres_id = presentationTmp.id;
    presentationTmp.id = linkedPresentation.id;
    presentationTmp.color = session.color;
    presentationTmp['code'] = linkedPresentation.code;
    presentationTmp['show_time'] = linkedPresentation.show_time;
    presentationTmp['start_time'] = linkedPresentation.start_time;
    presentationTmp['end_time'] = linkedPresentation.end_time;
    presentationTmp['session'] = session;
    presentationTmp['sess_id'] = session.id;
    presentationTmp['speakers'] = [];
    //person-block
    for (let k = 0; k < data['person_roles'].length; k++) {
        let role = data['person_roles'][k];
        if (role.presentation_id == presentationTmp.pres_id) {
            for (let l = 0; l < data['persons'].length; l++) {
                let person = data['persons'][l];
                if (person.id == role.person_id) {
                    person.function = role.function;
                    if (JSON.stringify(presentationTmp['speakers']).indexOf(person.id) == -1) {
                        presentationTmp['speakers'].push(person);
                    }
                }
            }
        }
    }
    return presentationTmp;

},

    async getPresentationLinkFavorites({dispatch}, ids) {
        let presentationsToReturn = [];
        let presentationTmp = null
        let data = await dispatch('database/getMultiple', ['presentations', 'session_presentation_links', 'sessions', 'person_roles', 'persons'], {root: true});

        let linkedPresentations = data['session_presentation_links'].filter(link => ids.indexOf(link.id) != -1);

        for (let i = 0; i < linkedPresentations.length; i++) {
            let linkedPresentation = linkedPresentations[i];
            if (linkedPresentation) {
                let tmp = data['presentations'].find(pres => pres.id == linkedPresentation.presentation_id);
                presentationTmp = JSON.parse(JSON.stringify(tmp));
                let session = data['sessions'].find(sess => sess.id == linkedPresentation.session_id);
                presentationTmp.pres_id = presentationTmp.id;
                presentationTmp.id = linkedPresentation.id;
                presentationTmp.color = session.color;
                presentationTmp['code'] = linkedPresentation.code;
                presentationTmp['show_time'] = linkedPresentation.show_time;
                presentationTmp['start_time'] = linkedPresentation.start_time;
                presentationTmp['end_time'] = linkedPresentation.end_time;
                presentationTmp['session'] = session;
                presentationTmp['sess_id'] = session.id;
                presentationTmp['speakers'] = [];
                //person-block
                for (let k = 0; k < data['person_roles'].length; k++) {
                    let role = data['person_roles'][k];
                    if (role.presentation_id == presentationTmp.pres_id) {
                        for (let l = 0; l < data['persons'].length; l++) {
                            let person = data['persons'][l];
                            if (person.id == role.person_id) {
                                person.function = role.function;
                                if (JSON.stringify(presentationTmp['speakers']).indexOf(person.id) == -1) {
                                    presentationTmp['speakers'].push(person);
                                }
                            }
                        }
                    }
                }
                presentationsToReturn.push(presentationTmp)

            }
        }


        return presentationsToReturn;


    },
    async getAbstractForPresentation({dispatch}, id) {
        let abstract = {};
        let data = await dispatch('database/getMultiple', ['abstracts', 'presentation_abstract_links'], {root: true});
        let abslink = data['presentation_abstract_links'].find(pal => pal.presentation_id == id);
        if (abslink) {
            abstract = data['abstracts'].find(abs => abs.id == abslink.abstract_id);
        }
        return abstract;
    },

    async getAllPosters({dispatch}) {
        let presentations =  await dispatch('database/getItems', {table: 'presentations'}, {root: true})
        let posters = [];
        for(let i= 0;  i<presentations.length;i++){
            console.log("a ")
            presentations[i].id = presentations[i].id.substring(0,25) + '3' + presentations[i].id.substring(25+1)
        }

        posters = presentations.filter(presentation=> presentation.code && presentation.code.indexOf('P') == 0 && presentation.code.length <= 10);
        console.log(posters)

        return posters.sort((a,b)=> {return a.code.localeCompare(b.code)});
    },

}

const mutations = {}
const getters = {}

export const presentations = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
