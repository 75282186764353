const namespaced = true
const config = require('@/config');

const state = {}

const actions = {
    async getAllExhibitors({dispatch}) {
        return await dispatch('database/getItems', {table: 'exhibitors'}, {root: true})
    },
    async getExhibitor({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['locations', 'exhibitor_location_links'], {root: true});

        let exhibitor = await dispatch('database/getItem', {table: 'exhibitors', id: id}, {root: true});


        if (exhibitor.is_exhibitor) {
            exhibitor.locationList = [];
            for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                let location_link = data['exhibitor_location_links'][j];
                if (location_link.exhibitor_id == exhibitor.id) {
                    for (let k = 0; k < data['locations'].length; k++) {
                        let location = data['locations'][k];
                        if (location_link.location_id == location.id) {
                            exhibitor.locationList.push(location);
                        }
                    }
                }
            }
        }
        return exhibitor;
    },
     async getExhibitorsById({dispatch}, ids) {
        let exhibitors = await dispatch('database/getItems', {table: 'exhibitors'}, {root: true});
        let exhibitorsToReturn = [];

        exhibitorsToReturn = exhibitors.filter(exhib=>ids.indexOf(exhib.id) != -1);
        return exhibitorsToReturn
    },
    async getList({dispatch}) {

        /*   let restrictedPlatformSponsors = []'83f3b9f1-b337-424a-9ca1-0a7dc5337615',
             '22880f59-1a03-4902-a83b-1f295936d244',
              '36308b28-1991-4f46-ba98-4ecf621c8caf',
              '472a6c2a-6493-40c8-a817-35a185b01637',
              'cfa0521c-1578-4623-8039-3ec2d55cf35d',
              'c9088776-e3ca-4223-a6a1-cfe15590e008',
              'ad5b3455-a82e-4b51-aa56-8f54b523baf2',
              'ca74aaff-87ed-4eb0-b0b7-61c3774fc0c5',
              '9e21f9fa-01bb-4d5f-934c-7ac523f14228',
              '7db1187c-893b-4f60-b46f-580417f5a242',
              '832ce9d5-5a58-4b1f-b33e-bb9e612362e8',
              'a9748010-fe91-41e5-ac93-312e1f576145',
              '9ec7a914-f971-4203-84fd-498646ee9787',
              '4913b548-b1dc-49a0-9019-62bba7744d12',
              '38c73f5a-fe58-413d-8dae-0299c8374f03',
              '29cd6dcf-f136-4778-b2d5-ea359a0268ca',
              'ea1b0f47-1c43-4dda-a08f-834fd70be09c',
              '7e948de1-095e-400d-833f-acc19d004cbf',
              '4f56db6d-e35a-4dfa-9b4d-f6daf18f0a82'
          ]*/
        let exhibitions = [];
        let data = await dispatch('database/getMultiple', ['exhibitors', 'locations', 'exhibitor_location_links'], {root: true});

        if (!config.is_app) {
            for (let i = 0; i < data['exhibitors'].length; i++) {
                let exhibitor = data['exhibitors'][i];
                // if (restrictedPlatformSponsors.indexOf(exhibitor.id) != -1) {
                if (exhibitor.is_exhibitor) {
                    exhibitor.locationList = [];
                    for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                        let location_link = data['exhibitor_location_links'][j];
                        if (location_link.exhibitor_id == exhibitor.id) {
                            for (let k = 0; k < data['locations'].length; k++) {
                                let location = data['locations'][k];
                                if (location_link.location_id == location.id) {
                                    exhibitor.locationList.push(location);
                                }
                            }
                        }
                    }
                    exhibitions.push(exhibitor)
                }
            }
            // }
        } else {
            for (let i = 0; i < data['exhibitors'].length; i++) {
                let exhibitor = data['exhibitors'][i];
                if (exhibitor.is_exhibitor) {
                    exhibitor.locationList = [];
                    for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                        let location_link = data['exhibitor_location_links'][j];
                        if (location_link.exhibitor_id == exhibitor.id) {
                            for (let k = 0; k < data['locations'].length; k++) {
                                let location = data['locations'][k];
                                if (location_link.location_id == location.id) {
                                    exhibitor.locationList.push(location);
                                }
                            }
                        }
                    }
                    exhibitions.push(exhibitor)
                }
            }
        }

        return exhibitions;
    },
    async getListForLocation({dispatch}, id) {

        let exhibitions = [];
        let data = await dispatch('database/getMultiple', ['exhibitors', 'locations', 'exhibitor_location_links'], {root: true});

        for (let i = 0; i < data['exhibitor_location_links'].length; i++) {
            let link = data['exhibitor_location_links'][i];
            if (link.location_id == id) {
                let exhibitor = data['exhibitors'].find(e => e.id == link.exhibitor_id);
                exhibitor.locationList = [];
                for (let j = 0; j < data['exhibitor_location_links'].length; j++) {
                    let location_link = data['exhibitor_location_links'][j];
                    if (location_link.exhibitor_id == exhibitor.id) {
                        for (let k = 0; k < data['locations'].length; k++) {
                            let location = data['locations'][k];
                            if (location_link.location_id == location.id) {
                                exhibitor.locationList.push(location);
                            }
                        }
                    }
                }
                exhibitions.push(exhibitor)
            }
        }

        return exhibitions;
    },

    async getSponsorList({dispatch}) {
        let data = await dispatch('database/getMultiple', ['sponsorlevels', 'exhibitor_sponsorlevel_links', 'exhibitors', 'locations', 'exhibitor_location_links'], {root: true});


        let list = [];
        let levels = data['sponsorlevels'].sort((a, b) => a.order - b.order);
        let exhibitorLevels = data['exhibitor_sponsorlevel_links'].sort((a, b) => a.order - b.order);
        for (let i = 0; i < levels.length; i++) {
            let sponsorlevel = levels[i];
            if (sponsorlevel.id != '9e9dbd30-aab8-4cd3-b176-54d9a5eb542b') {
                for (let j = 0; j < exhibitorLevels.length; j++) {

                    if (!config.is_app) {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {


                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }

                    } else {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {

                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }
                    }

                }
            }

        }
        return list;
    },

    async getSponsorListForLevel({dispatch}, level) {
        let data = await dispatch('database/getMultiple', ['sponsorlevels', 'exhibitor_sponsorlevel_links', 'exhibitors', 'locations', 'exhibitor_location_links'], {root: true});


        let list = [];
        let levels = data['sponsorlevels'].sort((a, b) => a.order - b.order);
        let exhibitorLevels = data['exhibitor_sponsorlevel_links'].sort((a, b) => a.order - b.order);
        for (let i = 0; i < levels.length; i++) {
            let sponsorlevel = levels[i];
            if (sponsorlevel.id == level) {
                for (let j = 0; j < exhibitorLevels.length; j++) {

                    if (!config.is_app) {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {


                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }

                    } else {
                        let sponsorlevel_link = exhibitorLevels[j];

                        if (sponsorlevel_link.sponsorlevel_id == sponsorlevel.id) {

                            if (list.indexOf(sponsorlevel.title) == -1) {
                                list.push(sponsorlevel.title)
                            }
                            for (let k = 0; k < data['exhibitors'].length; k++) {
                                let exhibitor = data['exhibitors'][k];
                                if (sponsorlevel_link.exhibitor_id == exhibitor.id) {
                                    exhibitor.locationList = [];
                                    exhibitor.order = sponsorlevel_link.order;

                                    for (let l = 0; l < data['exhibitor_location_links'].length; l++) {
                                        let location_link = data['exhibitor_location_links'][l];

                                        if (location_link.exhibitor_id == exhibitor.id) {
                                            for (let m = 0; m < data['locations'].length; m++) {
                                                let location = data['locations'][m];
                                                if (location_link.location_id == location.id) {
                                                    exhibitor.locationList.push(location.name);
                                                }
                                            }
                                        }
                                    }
                                    exhibitor.level_name = sponsorlevel.title;
                                    exhibitor.level_order = sponsorlevel.order;
                                    list.push(exhibitor)
                                }
                            }
                        }
                    }

                }
            }

        }
        return list;
    },

    async getMedias({dispatch}, params) {
        let id = params.id;
        let type = params.type;
        let medias = [];
        let data = await dispatch('database/getMultiple', ['medias', 'exhibitor_media_links'], {root: true})
        for (let i = 0; i < data['exhibitor_media_links'].length; i++) {
            let media_link = data['exhibitor_media_links'][i];
            if (media_link.exhibitor_id == id) {
                for (let j = 0; j < data['medias'].length; j++) {
                    let media = data['medias'][j];
                    if (media_link.media_id == media.id && media.type == type) {
                        medias.push(media);
                    }
                }
            }
        }
        return (medias.sort((a, b) => a.order - b.order));
    },


    async getLocations({dispatch}, id) {
        let locations = [];
        let data = await dispatch('database/getMultiple', ['locations', 'exhibitor_location_links'], {root: true})
        for (let i = 0; i < data['exhibitor_location_links'].length; i++) {
            let location_link = data['exhibitor_location_links'][i];
            if (location_link.exhibitor_id == id) {
                for (let j = 0; j < data['locations'].length; j++) {
                    let location = data['locations'][j];
                    if (location_link.location_id == location.id) {
                        locations.push(location)
                    }
                }
            }
        }
        return (locations);
    },

    async getDetails({dispatch}, exhibitor) {
        let details = {};
        details.locations = await dispatch('getLocations', exhibitor);
        details.files = await dispatch('getMedias', {id: exhibitor, type: 'file'});
        details.links = await dispatch('getMedias', {id: exhibitor, type: 'link'});
        details.videos = await dispatch('getMedias', {id: exhibitor, type: 'video'});
        details.virtualbooth = await dispatch('getMedias', {id: exhibitor, type: 'virtualbooth'});

        return details;
    },


}
const mutations = {}
const getters = {}

export const exhibitors = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
