function getEndpoints(){
    return [
      'settings',
      'configurations',
      'news',
      'polls',
      'questions',
      'embargos',
      'abstracts',
      'attendees',
      'banners',
      'banner_states',
      'exhibitors',
      'exhibitor_sponsorlevel_links',
      'subpages',
      'locations',
      'location_map_links',
      'maps',
      'medias',
      'menulinks',
      'persons',
      'person_roles',
      'presentations',
      'sessions',
      'session_presentation_links',
      'session_location_links',
      'session_sessiontype_links',
      'presentation_abstract_links',
      'exhibitor_location_links',
      'exhibitor_media_links',
      'exhibitor_tag_links',
      'session_tag_links',
      'sessiontypes',
      'sponsorlevels',
      'tags',
      'templates',
      'tiles',
      'topics',
      'abstract_topic_links',
    ];
  }
  
  module.exports = {getEndpoints};
