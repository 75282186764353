import axios from 'axios';

const namespaced = true;
const config = require('@/config');

/*
###DONE###

Route::prefix('room')->group(function () {
Route::post('/', [Controllers\RoomController::class, 'create']);
Route::get('/', [Controllers\RoomController::class, 'list']);
Route::get('/{id}', [Controllers\RoomController::class, 'read']);
Route::put('/{id}', [Controllers\RoomController::class, 'update']);
});
Route::prefix('message')->group(function () {
Route::post('/', [Controllers\MessageController::class, 'create']);
Route::get('/', [Controllers\MessageController::class, 'list']);
Route::get('/{id}', [Controllers\MessageController::class, 'read']);
Route::put('/{id}', [Controllers\MessageController::class, 'update']);
});
Route::prefix('user')->group(function () {
Route::post('/', [Controllers\UserController::class, 'create']);
Route::get('/', [Controllers\UserController::class, 'list']);###DONE###
Route::get('/{id}', [Controllers\UserController::class, 'read']);###
Route::put('/{id}', [Controllers\UserController::class, 'update']);
});



Route::prefix('lead')->group(function () {
Route::post('/', [Controllers\LeadController::class, 'create']);
Route::get('/', [Controllers\LeadController::class, 'list']);
Route::get('/{id}', [Controllers\LeadController::class, 'read']);
Route::put('/{id}', [Controllers\LeadController::class, 'update']);
});
Route::prefix('meeting')->group(function () {
Route::post('/', [Controllers\MeetingController::class, 'create']);
Route::get('/', [Controllers\MeetingController::class, 'list']);
Route::get('/list/from', [Controllers\MeetingController::class, 'listFrom']);
Route::get('/list/to', [Controllers\MeetingController::class, 'listTo']);
Route::get('/{id}', [Controllers\MeetingController::class, 'read']);
Route::put('/{id}', [Controllers\MeetingController::class, 'update']);
});

 */

const state = {

    currentRoom: null,
    userlist: [],
    recommendedUserlist: [],
    roomlist: [],
    currentMessages: [],
    unreadMessages: 0,
    chatUser: {},
    unreadTotal: 0,
    recommendationOptions: {
        "Role": {
            "weighting": 3,
            "identifier": 'Roles'
        },
        "Practice": {
            "weighting": 2,
            "identifier": 'Practices'
        },
        "mainCountry": {
            "weighting": 1, //JUST FOR TESTING:
            "identifier": 'Country'
        }
    },
    //NEW STUFF
    meetinglist:[],
    meetinglistbyroom:[],
    leadlist:[],
    leadlistbyroom:[],
    currentMeeting:null,
    currentLead:null
}

const actions = {
    async registerUser({commit}, _user) {
        console.log('REG', _user)
        let user = {};

        let userinfo = _user.extra['kit2']['userInfo'];

        userinfo['usertype'] = 'user';
        console.log("extrainfo", userinfo)

        try {

            let picture = _user.picture ? _user.picture.split('/')[4] : ''
            user = (await axios.get(config.chat_url + 'user/' + _user.id, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;

            let params = {}

            if (user.avatar_id != picture) {
                params['avatar_id'] = picture
            }

            if (user.additional_content != userinfo) {
                params['additional_content'] = userinfo
            }


            user = (await axios.put(config.chat_url + 'user/' + user.id, params, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;


        } catch (err) {
            let params = {
                id: _user.id,
                firstname: _user.firstName,
                lastname: _user.lastName,
                avatar_id: _user.picture ? _user.picture.split('/')[4] : '',
                additional_content: userinfo
            }
            console.log(params)
            user = (await axios.post(config.chat_url + 'user', params, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        }
        console.log('chatuser', user)
        commit('setChatUser', user)
    },
    async forceUserCreate({state}, _user) {
        console.log('REG', _user, state.chatUser)
        let user = {};
        try {
            user = (await axios.get(config.chat_url + 'user/' + _user.id, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        } catch (err) {
            let params = _user;
            user = (await axios.post(config.chat_url + 'user', params, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        }
        console.log(user)
    },

    async getUserList({commit}, user) {
        let list = (await axios.get(config.chat_url + 'user', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;

        let tmp = list.sort((a, b) => {
            return a.lastname.localeCompare(b.lastname);
        });
        tmp = tmp.filter(u => {
            return u.additional_content.usertype == 'user' && u.id != user.id
        });
        commit('setUserList', tmp)

    },


    async getRecommendedUserList({commit}, user)  {

        let usersWithRecommendations = [];

        let list = (await axios.get(config.chat_url + 'user', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        let tmp = list.filter(u => {
            return u.additional_content.usertype == 'user' && u.id != user.id
        });
        for (let [key, value] of Object.entries(state.recommendationOptions)) {
            for (let i = 0; i < tmp.length; i++) {

                if (tmp[i].additional_content[key] && tmp[i].additional_content[key].length > 0) {
                    let tmpuser = usersWithRecommendations.find(u => u.id == tmp[i].id)
                    if (!tmpuser) {
                        usersWithRecommendations.push(tmp[i]) //only once!
                    }
                }
            }
        }
        commit('setRecommendedUserList', usersWithRecommendations)
    },

    async getRoomList({commit}) {
        let list = await axios.get(config.chat_url + 'room', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log(list)
        commit('setRoomList', list.data)
    },

    async getMessagesInRoom({commit, state}) {
        let messages = (await axios.get(config.chat_url + 'message/room/' + state.currentRoom + '/list', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;

        commit('setCurrentMessages', messages)
    },
    async getUnreadMessagesTotalCount({commit}) {

        let unreadCount = (await axios.get(config.chat_url + 'message/unread/count', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        console.log('getunreads', unreadCount)
        commit('setUnreadCountTotal', unreadCount)
    },

    async enterRoom({commit}, ids) {
        //TODO:check if room exists
        let params = {
            "name": "Privat Test",
            "users": ids
        }

        let room = (await axios.post(config.chat_url + 'room', params, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        console.log(room)
        commit('setCurrentRoom', room.id)
    },

    async sendMessage({state}, text) {
        let params = {
            text: text,
            from_user_id: state.chatUser.id,
            room_id: state.currentRoom
        }

        await axios.post(config.chat_url + 'message', params, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },
    async markMessageAsRead({state}, id) {
        console.log(state)
        await axios.put(config.chat_url + 'message/' + id + '/read', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },

    //NEW MEETING AND LEAD FUNCTIONS
    async getMeetingList({commit}) {
        let list = await axios.get(config.chat_url + 'meeting', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log(list)
        commit('setMeetingList', list.data)
    },

    async getMeetingListByRoom({commit, state}) {
        console.log("state")
        console.log(state.currentRoom)
        let list = await axios.get(config.chat_url + 'meeting', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log(list)
        let filteredList = list.data.filter(item=>item.room_id == state.currentRoom)
        commit('setMeetingListByRoom', filteredList)
    },
    async getMeeting({commit},id) {

        let meeting = (await axios.get(config.chat_url + 'meeting/' + id, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        commit('setCurrentMeeting', meeting)
    },
    async sendMeetingRequest({state}, request) {
        
        await axios.post(config.chat_url + 'meeting', request, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },
    async answerMeetingRequest({state}, request) {

        
        await axios.put(config.chat_url + 'meeting/' + request.id, request, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },
    async getLeadList({commit}) {
        let list = await axios.get(config.chat_url + 'lead', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log(list)
        commit('setLeadList', list.data)
    },

    async getLeadListByRoom({commit, state}) {
        console.log("state")
        console.log(state.currentRoom)
        let list = await axios.get(config.chat_url + 'lead', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log("list")

        console.log(list)
        let filteredList = list.data.filter(item=>item.room_id == state.currentRoom)
        commit('setLeadListByRoom', filteredList)
    },
    async getLead({commit},id) {

        let lead = (await axios.get(config.chat_url + 'lead/' + id, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        commit('setCurrentLead', lead)
    },
    async createLead({state}, lead) {
        await axios.post(config.chat_url + 'lead', lead, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },
    async editLead({state}, lead) {
        await axios.put(config.chat_url + 'lead/' + lead.id, lead, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },


}
const mutations = {
    setCurrentRoom(state, currentRoom) {
        state.currentRoom = currentRoom;
    },

    setUserList(state, userlist) {
        state.userlist = userlist;
    },

    setRecommendedUserList(state, recommendedUserlist) {
        state.recommendedUserlist = recommendedUserlist;
    },

    setChatUser(state, user) {
        state.chatUser = user;
    },

    setRoomList(state, roomlist) {
        state.roomlist = roomlist;
    },

    setUnreadMessages(state, messagecount) {
        state.unreadMessages = messagecount;
    },
    setUnreadCountTotal(state, messagecount) {
        state.unreadTotal = messagecount;
    },

    setCurrentMessages(state, messages) {
        state.currentMessages = messages;
    },

    addMessage(state, message) {
        let msg = state.currentMessages.find(m => m.id == message.id);
        if (!msg) {
            state.currentMessages.push(message)
        }
    },

    //NEW MEETING AND LEAD FUNCTIONS
    setMeetingList(state, meetinglist) {
        state.meetinglist = meetinglist;
    },
    setMeetingListByRoom(state, meetinglistbyroom) {
        state.meetinglistbyroom = meetinglistbyroom;
    },
    setLeadList(state, leadlist) {
        state.leadlist = leadlist;
    },
    setLeadListByRoom(state, leadlistbyroom) {
        state.leadlistbyroom = leadlistbyroom;
    },
    setCurrentMeeting(state, meeting) {
        state.currentMeeting = meeting;
    },
    setCurrentLead(state, lead) {
        state.currentLead = lead;
    },
}
const getters = {}

export const chat = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
