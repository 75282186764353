//variablen muessen pro instanz angepasst werden
module.exports = {
    mantelUrl : 'https://mantel-api.mobile.documedias.systems/v2/conferences/',
    baseUrl: 'https://mantel-api.mobile.documedias.systems/v2/checkall/',
    baseUrlDownload: 'https://mantel-api.mobile.documedias.systems/v2/downloadall/',
    baseUrlCDN: "https://documedias-188ab.kxcdn.com/",
    cdnInitStamp: "2021-07-25T13:12:33.808Z",
    conference: '1452',
    short_name: 'bibliocon2024',
    full_name: 'BiblioCon 2024',
    api_version: 2, //change this to api version 2 for new conferences!!!
    conf_date : '27 – 29 October',
    api_url : 'https://coredev.api.documedias.systems/api/',
    appointment_api_url : '',//'https://dev.appointment.documedias.systems/api/',
    auth_url : 'https://auth.documedias.com/',
    auth_dev_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoyMzEyLCJjb25ncmVzc0lkIjoxNDUyLCJkYXRlIjoiMjAyNC0wNC0wM1QxNDoyNjozOC41MDNaIiwiaWF0IjoxNzEyMTU0Mzk4fQ.hsndhfnnZi0uINBMkk8rMDjhkOmlNR3vcDsSsqfvde8',
    auth_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoyMzUyLCJjb25ncmVzc0lkIjoxNDUyLCJkYXRlIjoiMjAyNC0wNS0xM1QxMTozNzoxNC4xMjhaIiwiaWF0IjoxNzE1NjAwMjM0fQ.sAcxPgCYp9-g0xRfaW4Sar80E5Fgsl-5nqMEX6aig1w',
    vue_app_access_key : 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjo5LCJjb25ncmVzc0lkIjoxLCJkYXRlIjoiMjAxOC0xMC0wMVQwOTo0Njo0MC42MDlaIiwiaWF0IjoxNTM4Mzg3MjAwfQ.1Z8vRJF2gZtCuLDEWxQhNyvlYHS6YZsdJxvoYGfyo112afY8CofuEBP4_jvIPXhc',
    chat_url: 'https://bibliocon2024.chat.documedias.systems/api/',
    is_app: false, //unbedingt anpassen bevor App deploy
    favorite_api_url: 'https://bibliocon2024.planner.documedias.systems/api/program/favorites',
    is_multi_conf_app: false, //set true for society or Conf2Go,
    versionIOS:'v1.0.5 16',
    versionAndroid:'v1.0.5 16',

    main_color: '#E30613'
}
  