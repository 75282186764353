const namespaced = true

const state = {
    showSplashscreen: false,
    showPassword: true

}

const actions = {

}
const mutations = {
    setShowSplash: (state, data) => {
        state.showSplashscreen = data;
    },
    setShowPassword: (state, data) => {
        state.showPassword = data;
    },
}
const getters = {
}

export const splashscreen = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
