<template>
    <div class="header d-none d-xl-block" style="text-align:center">
      <div class="header-content" style="text-align:center">
       <img src="@/assets/banner.png"/>
      </div>
    </div>

</template>

<script>
import {
} from "@ionic/vue";
import { isPlatform } from '@ionic/vue';

export default {
  components: {
  },
  data() {
    return {
      isDesktop: null,
    }
  },
  computed: {
  },
  methods: {
 
  
  },
  async created() {
    this.isDesktop= isPlatform('desktop');
  }
};
</script>
<style lang="scss">

@media(max-width:768px){
  .header{
    //display: hidden;
  }
}

</style>
