import axios from 'axios';
const namespaced = true

const state = {
    tracks:[]
}

const actions = {
    guid : () => {
        // http://www.ietf.org/rfc/rfc4122.txt
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        return s.join("");
    },
    trackEvent: async ({dispatch, rootState}, data) => {
         let guid = await dispatch('guid');
        let user = data.user ? data.user.id : guid;

        let item = data.item;
        let event = data.event;
        let itemId = data.itemId;
        let type = data.type;
        let now = new Date();
        console.log('TRACK',user, item, event, now.toISOString())
        let trackingItem = {user: user,type:type, item:item, event:event, time:now.toISOString(),itemId:itemId, submitted : false};
        let currentCmeTracks = JSON.parse(localStorage.getItem('cme_tracks_'+ rootState.currentConference.currentConference) || '{}');
        
        currentCmeTracks[guid] = trackingItem;
        localStorage.setItem('cme_tracks_'+ rootState.currentConference.currentConference, JSON.stringify(currentCmeTracks));
    },
    uploadTrackingEvents: async ({state,rootState}) => {
        let cmeTracks = JSON.parse(localStorage.getItem('cme_tracks_'+ rootState.currentConference.currentConference) || '{}')
        console.log(state.tracks)
        let eventsToSubmit = {};
        Object.keys(cmeTracks).forEach(key=>{
            let entry = cmeTracks[key];
            if(!entry.submitted){
                eventsToSubmit[key] = entry
            }
        })
        console.log('local',Object.keys(cmeTracks).length)
        console.log('submit',Object.keys(eventsToSubmit).length)

        //TODO:url anpassen
        let cmeUrl = 'https://' + rootState.currentConference.short_name +  '.cme-point.documedias.systems/api/v1/tracking/upload/multiple';
        let result = ''
        try{
            if(Object.keys(eventsToSubmit).length > 0){
                let response = await axios.post(cmeUrl, eventsToSubmit);
                console.log(response);
                if(response.data){
                    let currentCmeTracks = JSON.parse(localStorage.getItem('cme_tracks_'+ rootState.currentConference.currentConference) || '{}');
                    response.data.forEach(item=>{
                        currentCmeTracks[item].submitted = true;
                    })
                    localStorage.setItem('cme_tracks_'+ rootState.currentConference.currentConference, JSON.stringify(currentCmeTracks));
                    result = true;
                }
            }else{
                result = false;
            }
        }catch(err){
            result = false;
        }
        return result;
    }
}
const mutations = {

    addMessage (state, msg) {
        // mutate state
        state.pushmessages.push(msg)
    },
    markAsRead (state, index) {
        // mutate state
        console.log(index)
        state.pushmessages.splice(index,1);
    }
}
const getters = {
}

export const cmetracking ={
    namespaced,
    state,
    getters,
    mutations,
    actions
}
