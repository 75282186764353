const namespaced = true

const state = {

    currentLanguage: 'de',
    wordings: {
        'de': {
            'FAVS': 'Favoriten',
            'NOTES': 'Notes',
            'MAIL_EXPORT': 'In Mail exportieren',
            'LOGIN': 'Einloggen',
            'IMPRINT': 'Impressum',
            'ABOUT': 'Über',
            'PRIVACY': 'Datenschutz',
            'SUPPORT': 'Support',
            'SEARCH': 'Suche',
            'ADD_FAVORITE': 'Favorit',
            'RM_FAVORITE': 'Favorit',
            'SAVE_CALENDAR': 'Im Kalender speichern',
            'CHAIR_HEADER': 'Moderation',
            'SPEAKER_HEADER': 'Referent:in',
            'PRESENTATION_HEADER': 'Präsentationen',
            'PERSON_SESSION_HEADER': 'Sitzungen dieser Person',
            'EVALUATE': 'Bewerten',
            'SESSION_HEADER': 'Session ',
            'SESSION_HEADER_ALSO': 'Auch hier präsentiert',
            'NOW_HINT': 'Es laufen zur Zeit keine Sitzungen',
            'SECTION_SESSION': 'Sessions',
            'SECTION_PRESENTATION': 'Presentations',
            'SECTION_PERSON': 'Personen',
            'SECTION_ABSTRACTS': 'Abstracts',
            'SECTION_INDUSTRY': 'Aussteller',
            'NEWS_HINT': 'Zur Zeit gibt es keine Nachrichten',
            'COMMENTS': 'Kommentare',
            'INTERACTIVE': 'Fragen',
            'NO_RESULT_FIRST': 'Es konnte kein Eintrag zu Ihrer Suche nach "',
            'NO_RESULT_SECOND': '" gefunden werden. Bitte versuchen Sie einen anderen Begriff.',
            'LOAD_COMMENTS': 'Kommentare werden geladen',
            'ENTER_COMMENT': 'Geben Sie Ihren Kommentar ein',
            'NO_FORUM': 'Es gibt noch keine Kommentare',
            'FORUM_LOGIN': 'Bitte loggen Sie sich ein, um Kommentare zu senden',
            'FORUM_SEND': 'Senden',
            'VOTING_LOGIN': 'Bitte melden Sie sich an, um an der Abstimmung teilzunehmen',
            'VOTING_CONTROVERSIAL': 'Kontrovers-Frage',
            'VOTE_ALREADY_SENT': 'Sie haben bereits abgestimmt',
            'VOTE_THANK': 'Vielen Dank für Ihre Abstimmung',
            'TOTAL_VOTES': 'Abstimmungen gesamt',
            'MY_QUESTION': 'Meine Frage',
            'ENTER_QUESTION': 'Geben Sie hier Ihre Frage ein',
            'QUESTION_SEND': ' Senden',
            'SEND_AS': 'Senden als ',
            'QUESTIONS_AUDIENCE': 'Fragen vom Publikum',
            'DATA_UPDATE': 'Aktualisieren',
            'PERSONAL_ACCOUNT': 'Mein Account',
            'ACCOUNT_EDIT': 'Profil bearbeiten',
            'ACCOUNT_EMAIL': 'Email',
            'ACCOUNT_PHONE': 'Telefon',
            'ACCOUNT_ADDRESS': 'Adresse',
            'ACCOUNT_COUNTRY': 'Land',
            'ACCOUNT_RESET': 'Anwendung zurücksetzen',
            'RESET_HEADER': 'Anwendung zurücksetzen',
            'RESET_TEXT': 'Hiermit setzen Sie die Daten der Anwendung zurück. Sind Sie sich sicher?',
            'MY_PROFILE': 'Mein Profil',
            'MY_CONGRESS': 'Mein Kongress',
            'LOGOUT': 'Ausloggen',
            'CONTACT_US': 'Kontaktieren Sie uns',
            'VISIT_BOOTH': 'Besuchen Sie unseren Stand',
            'ABOUT_US': 'Über uns',
            'RES_CENTRE': 'Ressourcenzentrum',
            'EXHIBITORS': 'Ausstellungen',
            'NO_BIOGRAPHY': 'Diese Person hat keine Biografie',
            'ADD_NOTE': ' Notiz',
            'EDIT_NOTE': 'Notiz',
            'DELETE_NOTE': ' Löschen',
            'SAVE_NOTE': 'Notiz speichern',
            'CLOSE': 'Schließen',
            'BOOTHS': 'Standnr.',
            'NO_BOOTHS': 'Dieser Sponsoren hat keinen Ausstellungsstand',
            'WEBSITE': 'Website',
            'SPECIAL_THANKS': 'Besonderer Dank',
            'NO_NEWS': 'Im Moment gibt es noch keine Nachrichten.',
            'SHOW_MAP': 'Ort',
            'SESSION_NO_ACCESS': 'Ihre Registrierung beinhaltet keinen Zugriff auf diesen Inhalt.',
            'EVALUATION_NOT_ACTIVE': 'Die Bewertung ist für diese Sitzung nicht aktiv',
            'PRESENTATION_ABSTRACT': 'Abstract',
            'INITIALIZING_DATA': 'Daten initialisieren',
            'CHECKING_DATA': 'Daten prüfen',
            'DELETE_ACCOUNT': 'Mein Konto löschen',
            'PERS_ACCOUNT': 'Persönliches Konto',
            'EXHIBITOR_WEBSITE': 'Besuche unsere Webseite',
            'DOWNLOAD': 'Lade herunter',
            'CALENDAR_SAVE': 'Sitzung erfolgreich gespeichert',
            'CALENDAR_SAVE_ERROR': 'Die Sitzung wurde nicht gespeichert. Fehler:',
            'SCANS_TITLE': 'Gescannte Sitzungen',
            'PERSON_SESSIONS_TITLE': 'Beteiligt an diesen Sitzungen',
            'PERSON_PRESENTATIONS_TITLE': 'Beteiligt an diesen Präsentationen',
            'TYPE_MESSAGE': 'Schreiben Sie Ihre Nachricht',
            'OPEN_DESCRIPTION': 'Beschreibung lesen',
            'SESSION_DESCRIPTION': 'Sitzungsbeschreibung',
            'ROOM_NO_ACCESS': 'Ihre Registrierung beinhaltet keinen Zugriff auf diese Inhalte.',
            'QANDA_RELOAD_AUDIO': 'Wenn Sie ein Echo hören oder den Stream oder die Q&A nicht sehen können, aktualisieren Sie bitte diese Seite.',
            'QANDA_RELOAD_VIDEO': 'Wenn Sie den Stream nicht sehen können, aktualisieren Sie bitte diese Seite.',
            'STREAM_START_SOON': 'Programm startet in Kürze',
            'CHAT_PARTICIPANTS': 'Teilnehmer:innen',
            'CHAT_MY_CHATS': 'Meine Chats',
            'CHAT_RECOMMENDATIONS': 'Networking Empfehlungen',
            'CHATS_LOADING': 'Chats Laden...',
            'FAVORITES_SYNC': 'Favoriten Sync...',
            'LOG_IN_QUESTION': 'Möchten Sie sich anmelden?',
            'LOG_OUT_QUESTION': 'Möchten Sie sich abmelden?',
            'YES_ANSWER': 'Ja',
            'NO_ANSWER': 'Nein',
            'START_CHAT': 'Chat',
            'RECOMMENDATIONS': 'Empfehlungen',
            'SESSION_RECOMMENDATIONS': 'Sitzung Empfehlungen',

            'RECOMMENDATIONS_POPUP': 'Diese Sitzung wird Ihnen auf Basis folgender Kriterien angezeigt: ',
            'RECOMMENDATIONS_USER_POPUP': 'Dieses User wird Ihnen auf Basis folgender Kriterien angezeigt: ',
            'RECOMMENDATIONS_LOGIN': 'Bitte melden Sie sich an, um empfohlene Sitzungen basierend auf Ihrem Profil anzuzeigen.',
            'RECOMMENDED_FOR': 'Empfohlen für:',
            'SCHEDULE_MEETING_BUTTON': 'Meeting planen',
            'SAVE_CONTACT_BUTTON': 'Als Kontakt speichern',
            'SAVE_CONTACT_SUCCESS': 'Der Kontakt wurde auf Ihrem Gerät gespeichert',
            'COMPLETE_MEETING_REQUEST': 'Bitte füllen Sie alle Felder der Anfrage aus',
            'SHARE_PROFILE': 'Kontaktdaten Teilen',          
            'SHARE_PROFILE_DESCRIPTION': 'Durch die Nutzung dieser Funktion teilen Sie Ihre Kontaktdaten mit ',
            'SHARE_PROFILE_PROMPT': 'Geben Sie hier Ihre Kontaktdaten ein: ',
            'NO_RECOMMENDATIONS': 'Aktualisieren Sie Ihre Profilinformationen, um Empfehlungen zu erhalten',
            'LOGIN_REQUIRED': 'Anmeldung erforderlich',
            'LOGIN_REQUIRED_MSG': 'Für die meisten Funktionen dieser App ist eine Anmeldung erforderlich',
            'NEW_MEETING_REQUEST': 'Neue Tregganfrage von',
            'DATE_MEETING_REQUEST': 'Datum',
            'LOCATION_MEETING_REQUEST': 'Standort',
            'SUBJECT_MEETING_REQUEST': 'Thema',
            'DETAILS_MEETING_REQUEST': 'Details',









        },
        'en': {
            'FAVS': 'Favorites',
            'NOTES': 'Notes',
            'MAIL_EXPORT': 'Export to Mail',
            'LOGIN': 'LOG IN',
            'IMPRINT': 'Imprint',
            'ABOUT': 'About',
            'PRIVACY': 'Privacy',
            'SUPPORT': 'Support',
            'SEARCH': 'Search',
            'ADD_FAVORITE': 'Favorite',
            'RM_FAVORITE': 'Saved',
            'SAVE_CALENDAR': 'Save to calendar',
            'CHAIR_HEADER': 'Chair',
            'SPEAKER_HEADER': 'Speaker',
            'PRESENTATION_HEADER': 'Presentations',
            'PERSON_SESSION_HEADER': 'Sessions of this person',
            'EVALUATE': 'Evaluate',
            'SESSION_HEADER': 'Session',
            'SESSION_HEADER_ALSO': 'Also presented in session',
            'NOW_HINT': 'No session running now or in the next 15 minutes',
            'SECTION_SESSION': 'Sessions',
            'SECTION_PRESENTATION': 'Presentations',
            'SECTION_PERSON': 'Persons',
            'SECTION_ABSTRACTS': 'Abstracts',
            'SECTION_INDUSTRY': 'Industry',
            'NEWS_HINT': 'For now, there are no messages yet. Please come back later.',
            'COMMENTS': 'Comments',
            'INTERACTIVE': 'Questions',
            'NO_RESULT_FIRST': 'We\'re sorry, we didn\'t find a search result for "',
            'NO_RESULT_SECOND': '". Please try again.',
            'LOAD_COMMENTS': 'Loading comments',
            'ENTER_COMMENT': 'Please enter your comment',
            'NO_FORUM': 'No comments activated',
            'FORUM_LOGIN': 'Please login to send comments',
            'FORUM_SEND': 'Send',
            'VOTING_LOGIN': 'Please login to participate in votings',
            'VOTING_CONTROVERSIAL': 'Controversial Question',
            'VOTE_ALREADY_SENT': 'You´ve already voted',
            'VOTE_THANK': 'Thank you for your vote',
            'TOTAL_VOTES': 'Total votes',
            'MY_QUESTION': 'My question',
            'ENTER_QUESTION': 'Please enter your question',
            'QUESTION_SEND': ' Send',
            'SEND_AS': 'Send as ',
            'QUESTIONS_AUDIENCE': 'Questions from the audience',
            'DATA_UPDATE': 'Data Update',
            'PERSONAL_ACCOUNT': 'Mein Account',
            'ACCOUNT_EDIT': 'Edit Profile',
            'ACCOUNT_EMAIL': 'Email',
            'ACCOUNT_PHONE': 'Phone',
            'ACCOUNT_ADDRESS': 'Address',
            'ACCOUNT_COUNTRY': 'Country',
            'ACCOUNT_RESET': 'Reset application data',
            'RESET_HEADER': 'Reset application data',
            'RESET_TEXT': 'This will re-initialize the whole application. Are you sure?',
            'MY_PROFILE': 'My Profile',
            'MY_CONGRESS': 'My Congress',
            'LOGOUT': 'Log Out',
            'VISIT_BOOTH': 'Visit our booth',
            'CONTACT_US': 'Contact us',
            'ABOUT_US': 'About us',
            'RES_CENTRE': 'Resource Center',
            'EXHIBITORS': 'Exhibitors',
            'NO_BIOGRAPHY': 'This person has no biography',
            'ADD_NOTE': 'Add Note',
            'EDIT_NOTE': 'Edit Note',
            'DELETE_NOTE': 'Delete Note',
            'SAVE_NOTE': 'Save Note',
            'CLOSE': 'Close',
            'BOOTHS': 'Visit our booth',
            'NO_BOOTHS': 'This Sponsor has no Booths',
            'WEBSITE': 'Website',
            'SPECIAL_THANKS': 'Special Thanks',
            'NO_NEWS': 'There are no Messages right now.',
            'SHOW_MAP': 'Show on map',
            'SESSION_NO_ACCESS': 'Your registration does not include access to this content.',
            'EVALUATION_NOT_ACTIVE': 'Evaluation is not active for this session',
            'PRESENTATION_ABSTRACT': 'Abstract',
            'INITIALIZING_DATA': 'Initializing data',
            'CHECKING_DATA': 'Checking data',
            'DELETE_ACCOUNT': 'Delete my account',
            'PERS_ACCOUNT': 'Personal account',
            'EXHIBITOR_WEBSITE': 'Visit our Website',
            'DOWNLOAD': 'Downloading',
            'CALENDAR_SAVE': 'Event saved succesfully',
            'CALENDAR_SAVE_ERROR': 'Event could not be saved. Error: ',
            'SCANS_TITLE': 'Scanned Sessions',
            'PERSON_SESSIONS_TITLE': 'Involved in these sessions',
            'PERSON_PRESENTATIONS_TITLE': 'Involved in these presentations',
            'TYPE_MESSAGE': 'Type your message',
            'OPEN_DESCRIPTION': 'Read description',
            'SESSION_DESCRIPTION': 'Session description',
            'ROOM_NO_ACCESS': 'Your registration does not include access to this content.',
            'QANDA_RELOAD_AUDIO': 'If you hear an echo or you cannot see the stream or the Q&A, please refresh this page or press F5.',
            'QANDA_RELOAD_VIDEO': 'If you cannot see the stream, please refresh this page.',
            'STREAM_START_SOON': 'Programme starting soon',
            'CHAT_PARTICIPANTS': 'Participants',
            'CHAT_MY_CHATS': 'My Chats',
            'CHAT_RECOMMENDATIONS': 'Networking Recommendations',
            'CHATS_LOADING': 'Loading Chats...',
            'FAVORITES_SYNC': 'Syncing favorites...',
            'LOG_IN_QUESTION': 'Do you want to log in?',
            'LOG_OUT_QUESTION': 'Do you want to log out?',
            'YES_ANSWER': 'Yes',
            'NO_ANSWER': 'No',
            'START_CHAT': 'Chat',
            'RECOMMENDATIONS': 'Recommendations',
            'SESSION_RECOMMENDATIONS': 'Session recommendations',

            'RECOMMENDATIONS_POPUP': 'This session is recommended for you based on: ',
            'RECOMMENDATIONS_USER_POPUP': 'This user is recommended for you based on: ',
            'RECOMMENDATIONS_LOGIN': 'Please log in to see recommended sessions based on your profile.',
            'RECOMMENDED_FOR': 'Recommended for: ',
            'SCHEDULE_MEETING_BUTTON': 'Schedule meeting',
            'SAVE_CONTACT_BUTTON': 'Save as contact',
            'SAVE_CONTACT_SUCCESS': 'The contact has been saved to your device',
            'COMPLETE_MEETING_REQUEST': 'Please fill all the fields of the request.',
            'SHARE_PROFILE': 'Share your contact data',
            'SHARE_PROFILE_DESCRIPTION': 'By using this feature, you can share your contact details with ',
            'SHARE_PROFILE_PROMPT': 'Enter your contact data here: ',
            'NO_RECOMMENDATIONS': 'Update your profile information to receive recommendations',
            'LOGIN_REQUIRED': 'Login required',
            'LOGIN_REQUIRED_MSG': 'Login is required for most features of this app',
            'NEW_MEETING_REQUEST': 'New meeting request from',
            'DATE_MEETING_REQUEST': 'Date',
            'LOCATION_MEETING_REQUEST': 'Location',
            'SUBJECT_MEETING_REQUEST': 'Subject',
            'DETAILS_MEETING_REQUEST': 'Details',








        },
        'fr': {
            'FAVS': 'Favoris',
            'NOTES': 'Notes',
            'MAIL_EXPORT': 'Exporter vers e-mail',
            'LOGIN': 'Connecter',
            'IMPRINT': 'Impression',
            'ABOUT': 'À propos',
            'PRIVACY': 'Confidentialité',
            'SUPPORT': 'Support',
            'SEARCH': 'Recherche',
            'ADD_FAVORITE': 'Ajouter aux favoris',
            'RM_FAVORITE': 'Supprimer des favoris',
            'SAVE_CALENDAR': 'Enregistrer dans le calendrier',
            'CHAIR_HEADER': 'Moderation',
            'SPEAKER_HEADER': 'Speaker',
            'PRESENTATION_HEADER': 'Présentation',
            'PERSON_SESSION_HEADER': 'Sessions de cette personne',
            'EVALUATE': 'Evaluez',
            'SESSION_HEADER': 'Session ',
            'SESSION_HEADER_ALSO': 'Également présenté en session',
            'NOW_HINT': 'Pas de session en cours ni dans les 15 prochaines minutes dans les 15 prochaines minutes',
            'SECTION_SESSION': 'Sessions',
            'SECTION_PRESENTATION': 'Présentation',
            'SECTION_PERSON': 'Personne',
            'SECTION_ABSTRACTS': 'Abstract',
            'SECTION_INDUSTRY': 'Industrie',
            'NEWS_HINT': 'Pas de message pour le moment.',
            'COMMENTS': 'Commentaires',
            'INTERACTIVE': 'Questions',
            'NO_RESULT_FIRST': 'Désolé pas résultat de recherche',
            'NO_RESULT_SECOND': 'Veuillez essayer à nouveau',
            'LOAD_COMMENTS': 'En cours, veuillez patienter',
            'ENTER_COMMENT': 'Veuillez saisir votre commentaire',
            'NO_FORUM': 'Pas de commentaire activé',
            'FORUM_LOGIN': 'Veuillez vous connecter pour ajouter vos commentaires',
            'FORUM_SEND': 'Envoyer',
            'VOTING_LOGIN': 'Veuillez vous connecter pour voter',
            'VOTING_CONTROVERSIAL': 'Question controversée ',
            'VOTE_ALREADY_SENT ': 'Vous avez déjà voté',
            'VOTE_THANK': 'Merci pour votre vote',
            'TOTAL_VOTES': 'Nombre total de votes',
            'MY_QUESTION': 'Ma question',
            'ENTER_QUESTION': 'Veuillez saisir votre question',
            'QUESTION_SEND': 'Envoyer',
            'SEND_AS': 'Envoyer comme',
            'QUESTIONS_AUDIENCE': "Question de l'assemblée",
            'DATA_UPDATE': 'Mettre à jour',
            'PERSONAL_ACCOUNT': 'Mon compte',
            'ACCOUNT_EDIT': 'Modifier le compte',
            'ACCOUNT_EMAIL': 'E-mail',
            'ACCOUNT_PHONE': 'Téléphoner',
            'ACCOUNT_ADDRESS': 'Adresse',
            'ACCOUNT_COUNTRY': 'Pays',
            'ACCOUNT_RESET': 'Réinitialiser l\'application',
            'RESET_HEADER': 'Réinitialiser les données d\'application',
            'RESET_TEXT': 'Cela réinitialisera toute l\'application. Êtes-vous sûr ?',
            'MY_PROFILE': 'Mon profil',
            'MY_CONGRESS': 'Mon congrès',
            'LOGOUT': 'Se déconnecter',
            'VISIT_BOOTH': 'Visitez notre stand',
            'CONTACT_US': 'Nous contacter',
            'ABOUT_US': 'À propos de nous',
            'RES_CENTRE': 'Centre de ressources',
            'EXHIBITORS': 'Expositions',
            'NO_BIOGRAPHY': 'Cette personne n\'a pas de biographie',
            'ADD_NOTE': 'Ajouter une note',
            'EDIT_NOTE': 'Modifier la note',
            'DELETE_NOTE': 'Supprimer la note',
            'SAVE_NOTE': 'Enregistrer la Note',
            'CLOSE': 'Fermer',
            'BOOTHS': 'Stand',
            'NO_BOOTHS': 'Ce sponsor n\'a pas de stand',
            'WEBSITE': 'Page web',
            'SPECIAL_THANKS': 'Remerciement Spécial',
            'NO_NEWS': 'Il n\'y a aucun message pour le moment.',
            'SHOW_MAP': 'Emplacement',
            'SESSION_NO_ACCESS': ' Votre inscription n\'inclut pas l\'accès à ce contenu.',
            'EVALUATION_NOT_ACTIVE': 'L\'évaluation n\'est pas active pour cette session',
            'PRESENTATION_ABSTRACT': 'Abstract',
            'INITIALIZING_DATA': 'Initialisation des données',
            'CHECKING_DATA': 'Vérification des données',
            'DELETE_ACCOUNT': 'Supprimer mon compte',
            'PERS_ACCOUNT': 'Compte personnel',
            'EXHIBITOR_WEBSITE': 'Visitez notre site Internet',
            'DOWNLOAD': 'Télécharger',
            'CALENDAR_SAVE': 'Session enregistrée avec succès',
            'CALENDAR_SAVE_ERROR': 'La session n\'a pas été enregistrée. Erreur:',
            'SCANS_TITLE': 'Sessions Scannées',
            'PERSON_SESSIONS_TITLE': 'Participant à ces sessions',
            'PERSON_PRESENTATIONS_TITLE': 'Participant à ces présentations',
            'TYPE_MESSAGE': 'Écrivez ici',
            'OPEN_DESCRIPTION': 'Lis la description',
            'SESSION_DESCRIPTION': 'Descriptif de la séance',
            'ROOM_NO_ACCESS': 'Your registration does not include access to this content.',
            'QANDA_RELOAD_AUDIO': 'If you hear an echo or you cannot see the stream or the Q&A, please refresh this page or press F5.',
            'QANDA_RELOAD_VIDEO': 'If you cannot see the stream, please refresh this page.',
            'STREAM_START_SOON': 'Programme starting soon',
            'CHAT_PARTICIPANTS': 'Participants',
            'CHAT_MY_CHATS': 'Mes Chats',
            'CHAT_RECOMMENDATIONS': 'Networking Recommandations',
            'CHATS_LOADING': 'Chargement des Chats',
            'FAVORITES_SYNC': 'Favorits Sync...',
            'LOG_IN_QUESTION': 'Do you want to log in?',
            'LOG_OUT_QUESTION': 'Do you want to log out?',
            'YES_ANSWER': 'Oui',
            'NO_ANSWER': 'Non',
            'START_CHAT': 'Chat',
            'RECOMMENDATIONS': 'Recommandations',
            'SESSION_RECOMMENDATIONS': 'Séances recommandées',

            'RECOMMENDATIONS_POPUP': 'Ces sessions vous sont recommandées en fonction de votre pays, de votre pratique et de vos rôles.',
            'RECOMMANDATIONS USER POPUP' : 'Cette personne vous est recommandée en fonction de : ',
            'RECOMMENDATIONS_LOGIN': 'Veuillez vous connecter pour voir les sessions recommandées en fonction de votre profil',
            'RECOMMENDED_FOR': 'Recommandé pour:',
            'SCHEDULE_MEETING_BUTTON': 'Planifier une réunion',
            'SAVE_CONTACT_BUTTON': 'Enregistrer le contact',
            'SAVE_CONTACT_SUCCESS': 'Veuillez remplir tous les champs de la demande.',
            'SHARE_PROFILE': 'Partagez vos coordonnées',
            'SHARE_PROFILE_DESCRIPTION': 'En utilisant cette fonctionnalité, vous partagerez vos coordonnées avec ',
            'SHARE_PROFILE_PROMPT': 'Entrez vos coordonnées ici: ',
            'NO_RECOMMENDATIONS': 'Mettez à jour les informations de votre profil pour recevoir des recommandations',
            'LOGIN_REQUIRED': 'Connexion requise',
            'LOGIN_REQUIRED_MSG': 'La connexion est requise pour la plupart des fonctionnalités de cette application',
            'NEW_MEETING_REQUEST': 'Nouvelle demande de réunion: ',
            'DATE_MEETING_REQUEST': 'Date',
            'LOCATION_MEETING_REQUEST': 'Emplacement',
            'SUBJECT_MEETING_REQUEST': 'Sujet',
            'DETAILS_MEETING_REQUEST': 'Détails',






        }

    }
}

const actions = {
    changeLanguage({state}, lang) {
        state.currentLanguage = lang;
    }

}
const mutations = {}
const getters = {}

export const wordings = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
