import Vue from 'vue'
import PouchDB from 'pouchdb';
import axios from 'axios';
import moment from "moment";


const namespaced = true

const state = {
    db: new PouchDB('personalDatabase', {revs_limit: 7, auto_compaction: true}),
    remoteDB: null,
    syncHandler: null,
    unreadNews: 0,
    shareFormat: 'dddd MMMM D yyyy HH:mm',
    shareNote: 'Note',
    types: {
        'sessions': 1,
        'presentations': 2,
        'abstracts': 3,
        'eposters': 4,
        'persons': 5,
        'exhibitors': 6
    }
}

const actions = {


    async getItem({state}, name) {
        try {
            let data = await state.db.get(name);
            // console.log('getpersonal', data);
            if (data) {
                return data.data;
            } else {
                return []
            }
        } catch (err) {
            //console.log(err)
            return []
        }

    },

    getItems({state}, range) {
        return new Promise((resolve, reject) => {
            state.db.allDocs({
                include_docs: true,
                startkey: range, endkey: range + '\uffff'
            }).then((result) => {
                let list = [];
                result.rows.map(row => {
                    let data = row.doc.data;
                    data.id = row.id
                    if (data && data.deleted_at == null) {
                        list.push(data);
                    }
                });
                resolve(list);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    get({dispatch}, id) {
        return dispatch('getItem', id);
    },

    getAll({dispatch}, raw) {
        return dispatch('getItems', raw);
    },

    async saveItem({state}, payload) {
        console.log("here")
        console.log(payload)
        let name = payload.name;
        let item = payload.item
        // console.log('save favorite', item)
        let _item = {};
        try {
            _item = await state.db.get(name);
            //console.log('item from db', _item)
            if (JSON.stringify(item) !== JSON.stringify(_item)) {
                _item.data = item
                _item._id = name;
                //console.log('item to save', _item)
                //console.log('saveit')
                state.db.put(_item).then((x) => {
                    return (x);
                });
            }
        } catch (err) {
            console.log(err);
            _item.data = item
            _item._id = name;
            // console.log('item to save', _item)
            //console.log('saveit')
            state.db.put(_item).then((x) => {
                return (x);
            });
        }


    },
    connectPersonalDB({state}) {
        // console.log('connectpersdb')
        if (!state.remoteDB) {
            let user = localStorage.getItem('user');
            if (user) {
                // console.log(JSON.parse(user).couch)

                /*state.remoteDB = new PouchDB('https://personal-db.documedias.com/' + JSON.parse(user).couch,
                    {
                        headers: {authorization: 'Basic aGVpbWRhbGw6ZGFsbGhlaW0='},
                        revs_limit: 1,
                        auto_compaction: true
                    });
                state.remoteDB.info().then(() => {
                    state.syncHandler = state.db.sync(state.remoteDB, {
                        live: true, retry: true
                    }).on('change', function () {
                        console.log('change')
                    }).on('paused', function () {
                        console.log('pause')
                    }).on('active', function () {
                        console.log('start')
                    }).on('error', function () {
                        console.log('stop')
                    });

                }).catch(err => {
                    console.log(err);
                });*/
            }
        }
    },

    async translateId({state, rootState}, payload) {
        console.log(state.unreadNews)
        let prefix = 'da7ad0c0-3ed1-4500-' + rootState.currentConference.currentConference + '-0';
        let sessOffset = 10000000000;
        let presOffset = 30000000000;
        let personOffset = 40000000000;
        let type = payload.type;
        let id = parseInt(payload.id);
        let translatedId = '';
        if (type == 'session') {
            translatedId = prefix + parseInt(sessOffset + id)
        }

        if (type == 'presentation') {
            translatedId = prefix + parseInt(presOffset + id)
        }

        if (type == 'person') {
            translatedId = prefix + parseInt(personOffset + id)
        }

        return translatedId
    },


    async fetchRemoteFavorites({state, rootState}, token) {
        let URL = 'https://favorites.api.documedias.systems/api/favorites/user/conference/';
        let res = await axios.get(URL + rootState.currentConference.currentConference, {
            headers: {
                'x-api-key': token
            }
        });
        console.log(state.unreadNews)
        console.log('REMOTE FAVs', res.data)
        return res.data
    },

    async fetchRemoteNotes({state, rootState}, token) {
        let URL = 'https://favorites.api.documedias.systems/api/notes/user/conference/';
        let res = await axios.get(URL + rootState.currentConference.currentConference, {
            headers: {
                'x-api-key': token
            }
        });
        console.log(state.unreadNews)
        console.log('REMOTE Notes', res.data)
        return res.data
    },
    async toggleRemoteFavorite({state, rootState}, id) {
        console.log(state)
        let type = state.types[id.split('/')[0]];
        console.log(type)
        let cleanId = type != 6 ? parseInt(id.split('-').pop().substr(2)) : id.split('/')[1];

        let checkURL = 'https://favorites.api.documedias.systems/api/favorites/reference_id/' + cleanId + '/reference_type_id/' + type;
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            //TODO: add or remove favorite for type and id and congress and user ;)

            let checkRes = await axios.get(checkURL, {
                headers: {
                    'x-api-key': user.token
                }
            });
            console.log(checkRes)
            if (checkRes.data.length == 0) {

                //CREATE
                let payload = {
                    "conference_id": rootState.currentConference.currentConference,
                    "reference_type_id": type,
                    "reference_id": cleanId
                }

                let res = axios.post("https://favorites.api.documedias.systems/api/favorites/create", payload, {
                    headers: {
                        'x-api-key': user.token
                    }
                })
                console.log("created " + res)
            } else {
                //DELETE

                let res = axios.delete("https://favorites.api.documedias.systems/api/favorites/delete/" + checkRes.data[0].id, {
                    headers: {
                        'x-api-key': user.token
                    }
                })
                console.log("deleeted " + res)
            }
        }
    },

    async saveRemoteNote({state, rootState}, {id, text}) {
        console.log('note', id, text)
        let type = state.types[id.split('/')[0]];

        let cleanId = type != 6 ? parseInt(id.split('-').pop().substr(2)) : id.split('/')[1];
        let checkURL = 'https://favorites.api.documedias.systems/api/notes/reference_id/' + cleanId + '/reference_type_id/' + type;
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            //TODO: add or remove favorite for type and id and congress and user ;)

            let checkRes = await axios.get(checkURL, {
                headers: {
                    'x-api-key': user.token
                }
            });
            console.log(checkRes)
            if (checkRes.data.length == 0) {

                //CREATE
                let payload = {
                    "conference_id": rootState.currentConference.currentConference,
                    "reference_type_id": type,
                    "reference_id": cleanId,
                    "note_text": text
                }

                let res = axios.post("https://favorites.api.documedias.systems/api/notes/create", payload, {
                    headers: {
                        'x-api-key': user.token
                    }
                })
                console.log("created " + res)
            } else {
                //PUT

                let payload = {
                    "conference_id": rootState.currentConference.currentConference,
                    "reference_type_id": type,
                    "reference_id": cleanId,
                    "note_text": text
                }

                let res = axios.put("https://favorites.api.documedias.systems/api/notes/edit/" + checkRes.data[0].id, payload, {
                    headers: {
                        'x-api-key': user.token
                    }
                })
                console.log("created " + res)
            }
        }
    },
    async deleteRemoteNote({state, rootState}, id) {
        let type = state.types[id.split('/')[0]];

        let cleanId = type != 6 ? parseInt(id.split('-').pop().substr(2)) : id.split('/')[1];
        let checkURL = 'https://favorites.api.documedias.systems/api/notes/reference_id/' + cleanId + '/reference_type_id/' + type;
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            let checkRes = await axios.get(checkURL, {
                headers: {
                    'x-api-key': user.token
                }
            });
            if (checkRes.data.length != 0) {
                //DELETE
                let res = axios.delete("https://favorites.api.documedias.systems/api/notes/delete/" + checkRes.data[0].id, {
                    headers: {
                        'x-api-key': user.token
                    }
                })
                console.log("deleeted " + res)
            }
        }
    },
    async toggleSession({state, rootState}, id) {
        console.log(state.unreadNews)
        let cleanId = parseInt(id.split('-').pop().substr(2));
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            try {
                let res = await axios.get('https://' + rootState.currentConference.short_name + '.planner.documedias.systems/api/program/favorites' + '/sessions/' + cleanId, {
                    headers: {
                        'x-api-key': user.token
                    }
                });
                console.log('fav from planner SESSION', res.data)
            } catch (err) {
                console.log('not found')
            }

        }
    },
    async togglePresentation({rootState}, data) {
        console.log('toggle pres', data)
        let cleanSessId = parseInt(data.sessId.split('-').pop().substr(2));
        let cleanPresId = parseInt(data.presId.split('-').pop().substr(2));
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            try {
                let res = await axios.get('https://' + rootState.currentConference.short_name + '.planner.documedias.systems/api/program/favorites' + '/sessions/' + cleanSessId + '/presentations/' + cleanPresId, {
                    headers: {
                        'x-api-key': user.token
                    }
                });
                console.log('fav from planner PRES', res)
            } catch (err) {
                console.log('not found')
            }

        }
    },


    async prepareFavoriteList({dispatch, rootState}, favorites) {
        const start = Date.now();
        let onLine =  navigator.onLine        
        let remoteFavorites = {};
        let user = localStorage.getItem('user');

        if (user && onLine) {
            user = JSON.parse(user);
            remoteFavorites = await dispatch('fetchRemoteFavorites', user.token);
            favorites = []; //set this to an empty array so favorites now depend completely on the favorite api and not on local data

        }
        let favoritesToReturn = {
            sessions: [],
            presentations: [],
            persons: [],
            exhibitors: []
        }

        if (remoteFavorites && remoteFavorites.length >0) {
            let remoteSessions = remoteFavorites.filter(s => s.reference_type_id == 1);
            let remotePresentations = remoteFavorites.filter(pr => pr.reference_type_id == 2);
            let remotePersons = remoteFavorites.filter(pe => pe.reference_type_id == 5);
            let remoteExhibitors = remoteFavorites.filter(ex => ex.reference_type_id == 6);

            for (let s = 0; s < remoteSessions.length; s++) {
                let sess = remoteSessions[s];
                let sessid = await dispatch('translateId', {type: 'session', id: sess.reference_id})
                if (favorites.indexOf('sessions/' + sessid) == -1) {
                    favorites.push('sessions/' + sessid)
                }

            }
            for (let p = 0; p < remotePresentations.length; p++) {
                let pres = remotePresentations[p];
                console.log('PRES ID PLanner', pres)
                let presid = await dispatch('translateId', {type: 'presentation', id: pres.reference_id})
                if (favorites.indexOf('presentations/' + presid) == -1) {
                    favorites.push('presentations/' + presid)
                }

            }
            for (let pers = 0; pers < remotePersons.length; pers++) {
                let person = remotePersons[pers];

                let personid = await dispatch('translateId', {type: 'person', id: person.reference_id})
                if (favorites.indexOf('persons/' + personid) == -1) {
                    favorites.push('persons/' + personid)
                }

            }
            for (let ex = 0; ex < remoteExhibitors.length; ex++) {
                let exhib = remoteExhibitors[ex];
                console.log('should be exhib id', exhib.reference_id)

                if (favorites.indexOf('exhibitors/' + exhib.reference_id) == -1) {
                    favorites.push('exhibitors/' + exhib.reference_id)
                }

            }
            await dispatch('saveItem', {
                name: 'favorites_' + rootState.currentConference.currentConference +'_v2',
                item: favorites
            })


        }
        let sessionIds = []
        let presentationIds = []
        let personIds = []
        let exhibitorIds = []

        for (let i = 0; i < favorites.length; i++) {
            let favorite = favorites[i];
            if (favorite) {
                let id = favorite.split('/')[1];
                if (favorite.indexOf('sessions/') == 0) {

                    sessionIds.push(id);
                    /*try {
                        let session = await dispatch('sessions/getSessionFavorite', id, {root: true})
                        if (JSON.stringify(favoritesToReturn).indexOf(session.id) == -1) {
                            favoritesToReturn.sessions.push(session);
                        }
                    } catch (err) {
                        console.log(err)
                    }*/
                }
                if (favorite.indexOf('presentations/') == 0) {
                    presentationIds.push(id)
                    /*try {
                        let presentation = await dispatch('presentations/getPresentationLinkFavorite', id, {root: true})
                        if(presentation){
                            if (JSON.stringify(favoritesToReturn).indexOf(presentation.id) == -1) {
                                favoritesToReturn.presentations.push(presentation);
                            }
                        }

                    } catch (err) {
                        console.log(err)
                    }*/
                }
                if (favorite.indexOf('persons/') == 0) {
                    personIds.push(id)
                    /*try {
                        let person = await dispatch('database/getItem', {table: 'persons', id: id}, {root: true})
                        if (JSON.stringify(favoritesToReturn).indexOf(person.id) == -1) {
                            favoritesToReturn.persons.push(person);
                        }
                    } catch (err) {
                        console.log(err)
                    }*/
                }
                if (favorite.indexOf('exhibitors/') == 0) {
                    exhibitorIds.push(id)
                    /*try {
                        let exhibitor = await dispatch('database/getItem', {table: 'exhibitors', id: id}, {root: true})
                        if (JSON.stringify(favoritesToReturn).indexOf(exhibitor.id) == -1) {
                            favoritesToReturn.exhibitors.push(exhibitor);
                        }
                    } catch (err) {
                        console.log(err)
                    }*/
                }
            }

        }

        favoritesToReturn.sessions = await dispatch('sessions/getSessionFavoriteForList', sessionIds, {root: true})
        favoritesToReturn.presentations = await dispatch('presentations/getPresentationLinkFavorites', presentationIds, {root: true})
        favoritesToReturn.persons = await dispatch('persons/getPersonsById', personIds, {root: true})
        favoritesToReturn.exhibitors = await dispatch('exhibitors/getExhibitorsById', exhibitorIds, {root: true})

        const end = Date.now();
        console.log(`Execution time favorites: ${end - start} ms`);

        return favoritesToReturn;
    },

    async prepareNoteList({dispatch, rootState}, notes) {
        const start = Date.now();
        let onLine = navigator.onLine
        let notesToReturn = {
            sessions: [],
            presentations: [],
            persons: [],
            exhibitors: []
        }
     
        console.log("local_notes:")
        console.log(notes)
        let remoteNotes = {};
        let user = localStorage.getItem('user');
        if (user && onLine) {
            user = JSON.parse(user);
            remoteNotes = await dispatch('fetchRemoteNotes', user.token);
            notes = []; //set this to an empty array so favorites now depend completely on the favorite api and not on local data

        }

        if (remoteNotes && remoteNotes.length >0) {
            let remoteSessions = remoteNotes.filter(s => s.reference_type_id == 1);
            let remotePresentations = remoteNotes.filter(pr => pr.reference_type_id == 2);
            let remotePersons = remoteNotes.filter(pe => pe.reference_type_id == 5);
            let remoteExhibitors = remoteNotes.filter(ex => ex.reference_type_id == 6);

            for (let s = 0; s < remoteSessions.length; s++) {
                let sess = remoteSessions[s];
                let sessid = await dispatch('translateId', {type: 'session', id: sess.reference_id})
                if (notes.indexOf('sessions/' + sessid) == -1) {
                    notes.push({id: 'sessions/' + sessid, note: sess.note_text})
                }

            }
            for (let p = 0; p < remotePresentations.length; p++) {
                let pres = remotePresentations[p];
                console.log('PRES ID PLanner', pres)
                let presid = await dispatch('translateId', {type: 'presentation', id: pres.reference_id})
                if (notes.indexOf('presentations/' + presid) == -1) {
                    notes.push({id: 'presentations/' + presid, note: pres.note_text})
                }

            }
            for (let pers = 0; pers < remotePersons.length; pers++) {
                let person = remotePersons[pers];

                let personid = await dispatch('translateId', {type: 'person', id: person.reference_id})
                if (notes.indexOf('persons/' + personid) == -1) {
                    notes.push({id: 'persons/' + personid, note: person.note_text})
                }

            }
            for (let ex = 0; ex < remoteExhibitors.length; ex++) {
                let exhib = remoteExhibitors[ex];
                console.log('should be exhib id', exhib.reference_id)

                if (notes.indexOf('exhibitors/' + exhib.reference_id) == -1) {
                    notes.push({id: 'exhibitors/' + exhib.reference_id, note: exhib.note_text})
                }

            }
            await dispatch('saveItem', {name: 'notes_' + rootState.currentConference.currentConference +'_v2', item: notes})


        }
        let sessionIds = []
        let presentationIds = []
        let personIds = []
        let exhibitorIds = []

        for (let i = 0; i < notes.length; i++) {
            let note = notes[i];
            if (note) {
                let id = note.id.split('/')[1];
                if (note.id.indexOf('sessions/') == 0) {
                    sessionIds.push(id)
                    try {
                        let session = await dispatch('sessions/getSessionFavorite', id, {root: true})
                        session.note = note.note;
                        if (JSON.stringify(notesToReturn).indexOf(session.id) == -1) {
                            notesToReturn.sessions.push(session);
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                if (note.id.indexOf('presentations/') == 0) {
                    presentationIds.push(id)
                    try {
                        let presentation = await dispatch('presentations/getPresentationLinkFavorite', id, {root: true})
                        if(presentation){
                            presentation.note = note.note;
                            if (JSON.stringify(notesToReturn).indexOf(presentation.id) == -1) {
                                notesToReturn.presentations.push(presentation);
                            }
                        }

                    } catch (err) {
                        console.log(err)
                    }
                }
                if (note.id.indexOf('persons/') == 0) {
                    personIds.push(id)
                    try {
                        let person = await dispatch('database/getItem', {table: 'persons', id: id}, {root: true})
                        person.note = note.note;
                        if (JSON.stringify(notesToReturn).indexOf(person.id) == -1) {
                            notesToReturn.persons.push(person);
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                if (note.id.indexOf('exhibitors/') == 0) {
                    exhibitorIds.push(id)
                    try {
                        let exhibitor = await dispatch('database/getItem', {table: 'exhibitors', id: id}, {root: true})
                        exhibitor.note = note.note;
                        if (JSON.stringify(notesToReturn).indexOf(exhibitor.id) == -1) {
                            notesToReturn.exhibitors.push(exhibitor);
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
            }
        }
      /*  notesToReturn.sessions = await dispatch('sessions/getSessionFavoriteForList', sessionIds, {root: true})
        notesToReturn.presentations = await dispatch('presentations/getPresentationLinkFavorites', presentationIds, {root: true})
        notesToReturn.persons = await dispatch('persons/getPersonsById', personIds, {root: true})
        notesToReturn.exhibitors = await dispatch('exhibitors/getExhibitorsById', exhibitorIds, {root: true})*/

        const end = Date.now();
        console.log(`Execution time note: ${end - start} ms`);
        return notesToReturn;
    },


    async setTime({state, rootState}, payload) {

        let name = 'timeStamps_' + rootState.currentConference.currentConference;
        let item = payload.item;
        let timestamp = payload.timestamp;
        let oldTime = new Date('2000-01-01T00:00:00');
        //console.log('save favorite', item)
        let _item = {};
        try {
            _item = await state.db.get(name);
            //  console.log('item from db', _item)
            if (JSON.stringify(item) !== JSON.stringify(_item)) {

                oldTime = new Date((_item.data || '2000-01-01T00:00:00'));
                if (!timestamp || oldTime < timestamp) {
                    _item.data = item || new Date();
                }
                _item._id = name;
                //console.log('item to save', _item)
                //console.log('saveit')
                state.db.put(_item).then((x) => {
                    return (x);
                }).catch(err => {
                    if (err) {
                        err = 0;
                    }
                });
            }
        } catch (err) {

            //console.log(err);
            _item.data = item
            _item._id = name;
            //console.log('item to save', _item)
            //console.log('saveit')
            state.db.put(_item).then((x) => {
                return (x);
            });
        }


    },

    async getUnreadNews({state, rootState, dispatch}) {
        let news = await dispatch('database/getItems', {table: 'news'}, {root: true})

        return new Promise((resolve, reject) => {
            try {
                state.db.get('timeStamps_' + rootState.currentConference.currentConference).then(_item => {
                    //console.log('compare unread', _item.data)
                    let timeDiff = new Date((_item.data || '2000-01-01T00:00:00'));

                    let counter = 0;
                    news.forEach(newsItem => {

                        if (new Date((newsItem['publish_at'] || '2000-01-01T10:00:00')) > timeDiff && new Date((newsItem['publish_at'] || '2000-01-01T10:00:00')) < new Date()) {
                            counter++;
                        }

                    });
                    state.unreadNews = counter
                    resolve(counter);
                }).catch(x => {
                    reject(x)
                });
            } catch (err) {
                reject(err)
            }

        })
    },


    async prepareSessionShare({state}, session) {

        let start = moment(session.start_time).format(state.shareFormat)
        let end = moment(session.end_time).format(state.shareFormat)
        let location = session.location ? session.location.name : '';
        let share = session.session_type_text + '\r\n' + session.title + '\r\n' + start + ' - ' + end + '\r\n' + 'Location: ' + location;

        if (session.note) {
            share += '\r\n' + state.shareNote + ': ' + session.note;
        }
        return share
    },


    async preparePresentationShare({state}, presentation) {
        let start = moment(presentation.start_time).format(state.shareFormat)
        let end = moment(presentation.end_time).format(state.shareFormat)
        let share = start && end ? presentation.title + '\r\n' + start + ' - ' + end : presentation.title;
        if (presentation.location) {
            share += '\r\n' + presentation.location
        }
        if (presentation.note) {
            share += '\r\n' + state.shareNote + ': ' + presentation.note;
        }
        return share
    },


    async preparePersonShare({state}, person) {
        let share = person.display_name;
        if (person.note) {
            share += '\r\n' + state.shareNote + ': ' + person.note;
        }
        return share;
    },

    async prepareExhibitionShare({state}, exhibition) {
        let share = exhibition.name;
        if (exhibition.locationList) {

            share += '\r\n' + 'Booth' + ': ';
            let locationNames = [];
            exhibition.locationList.forEach(location => {
                locationNames.push(location.name);
            });
            share += locationNames.join(',')
        }
        if (exhibition.homepage) {
            share += '\r\n' + 'Web' + ': ' + exhibition.homepage;
        }
        if (exhibition.email) {
            share += '\r\n' + 'Email' + ': ' + exhibition.email;
        }
        if (exhibition.phone) {
            share += '\r\n' + 'Phone' + ': ' + exhibition.phone;
        }

        if (exhibition.note) {
            share += '\r\n' + state.shareNote + ': ' + exhibition.note;
        }
        return share;
    }

}
const mutations = {
    setDB: (state, data) => {
        Vue.set(state, 'db', data)
    },
    setUnreadNews: (state, data) => {
        state.unreadNews = data;
    }
}
const getters = {
    getDB: (state) => {
        return state.db;
    },
}

export const personalDB = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
