const namespaced = true

const state = {}

const actions = {
    async getAllMarkers({dispatch}, id) {
        let markers = await dispatch('database/getItems', {table: 'location_map_links'}, {root: true});

        return markers.filter(marker => marker.map_id == id);
    },

    async getMap({dispatch}, id) {
        return await dispatch('database/getItem', {table: 'maps', id: id}, {root: true})
    },
    async getMaps({dispatch}) {
        return await dispatch('database/getItems', {table: 'maps'}, {root: true})
    },

    async getMapFromLocation({dispatch}, id) {
        let data = await dispatch('database/getMultiple', ['maps', 'location_map_links'], {root: true})
        let mapLink = data['location_map_links'].find(link => link.location_id == id)
        let map = null;
        if (mapLink) {
            map = data['maps'].find(map => map.id == mapLink.map_id)
        }
        return map
    },

    async getMarkerForLocation({dispatch}, id) {
        let markers = await dispatch('database/getItems', {table: 'location_map_links'}, {root: true})
        let location = await dispatch('database/getItem', {table: 'locations', id: id}, {root: true})
        let marker = markers.filter(marker => marker.location_id == location.id)[0]
        marker.location = location;
        return marker;
    },
    async prepareMarker({dispatch}, marker) {
        let location = await dispatch('database/getItem', {table: 'locations', id: marker.location_id}, {root: true})
        let exhibitors = [];
        let sessions = [];
        let data = await dispatch('database/getMultiple', ['sessions', 'session_location_links', 'exhibitors', 'exhibitor_location_links'], {root: true})
      
        let exhibitorLocations = data['exhibitor_location_links'].filter(ell => ell.location_id == location.id);
        if (exhibitorLocations) {

            for (let i = 0; i < exhibitorLocations.length; i++) {
                let exhiblink = exhibitorLocations[i];
                for (let j = 0; j < data['exhibitors'].length; j++) {
                    let exhib = data['exhibitors'][j];
                    if (exhiblink.exhibitor_id == exhib.id) {
                        exhibitors.push(exhib)
                    }
                }
            }
        }
        let sessionLocations = data['session_location_links'].filter(sll => sll.location_id == location.id);
        if (sessionLocations) {

            for (let k = 0; k < sessionLocations.length; k++) {
                let sessionlink = sessionLocations[k];
                for (let l = 0; l < data['sessions'].length; l++) {
                    let session = data['sessions'][l];
                    if (sessionlink.session_od == session.id) {
                        sessions.push(session)
                    }
                }
            }
        }

        if (sessions.length > 1) {
            marker.name = marker.message || location.name;
            marker.link = '/app/locations/' + location.id;
        }
        if (exhibitors.length > 1) {
            let tmp = '';
            exhibitors.forEach(exhibitor => {
                tmp += exhibitor.name + '<br>';
            });
            marker.name = marker.message || tmp;
            marker.link = '/app/exhibitorlocations/' + location.id;
        }
        if (exhibitors.length === 1) {
            marker.name = marker.message || exhibitors[0].name;
            marker.link = '/app/exhibitors/' + exhibitors[0].id;
        }
        if (sessions.length === 1) {
            marker.name = marker.message || location.name;
            marker.link = '/app/sessions/' + sessions[0].id;
        }
        if (sessions.length + exhibitors.length === 0) {
            marker.name = marker.message || location.name;
            marker.link = '/app/locations/' + location.id;
        }
        return marker;
    }
}
const mutations = {}
const getters = {}

export const maps = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
