const namespaced = true

const state = {
    googleTracks:[],
    vm:null
}

const actions = {

    trackWithLabel : ({state, rootState},label) =>{
        console.log(rootState.currentConference.currentConference, location.hash, label)
        //state.vm.$ga.event((rootState.currentConference.currentConference || ''), location.hash, label);
        state.vm.$gtag.event(location.hash, {'event_category': (rootState.currentConference.currentConference || ''),'event_label': label, 'value':label});
    },

    trackWithIdAndLabel : ({state}, data) => {
        let id = data.id;
        let label = data.label
        //state.vm.$ga.event(id, location.hash, label);
        state.vm.$gtag.event(location.hash, {'event_category': id,'event_label': label, 'value':label});

    },

    trackWithIdAndCategoryAndLabel : ({state}, data) =>{
        let id = data.id;
        let label = data.label
        let category = data.category
        //state.vm.$ga.event(id, category, label);
        state.vm.$gtag.event(category, {'event_category': id,'event_label': label, 'value':label});

    },

    add : ({state, rootState}, data) => {
        let id = data.id;
        let label = data.label
        let category = data.category
      //  state.vm.$ga.event(id || (rootState.currentConference.currentConference|| ''), category || location.hash, label);
        state.vm.$gtag.event(id || (rootState.currentConference.currentConference|| ''), {'event_category': category || location.hash,'event_label': label, 'value':label});

    }
}

const mutations = {
    setVM : (state, value) =>{
        state.vm = value;
    }

}
const getters = {
}

export const googleanalytics ={
    namespaced,
    state,
    getters,
    mutations,
    actions
}
